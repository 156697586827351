/* ------------------------------------------------------------
     MODAL 10
------------------------------------------------------------ */
.box--modal10 {
  background: url(../../../../public/assets/images/elements/ticket.jpg) center center no-repeat;
  background-size: cover;
  article {
    padding: 32px;
    background: $c-Green700;
    border-radius: 24px;
    max-width: 600px;
    width: 100%;
    text-align: left;
    position: relative;
  }
  .box-modal-cols {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 54px;
  }
  .mid-title {
    margin-bottom: 32px;
  }
  .lottie-player {
    width: 270px;
    height: 380px;
    left: initial;
    right: -70px;
    top: -110px;
  }
}


/* -----

MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
 
}
@media screen and (max-width: 1200px) {
  
}
@media screen and (max-width: 992px) {
  
}
@media screen and (max-width: 768px) {
  .box--modal10 {
    background: url(../../../../public/assets/images/elements/ticket-mobile.jpg) center center no-repeat;
    background-size: cover;
    .box-modal-cols {
      padding-bottom: 60px;
      align-items: flex-end;
    }
    article {
      padding-right: 30%;
    }
  }
}
@media screen and (max-width: 441px) {
  .box--modal10 {
    .box-modal-cols {
      padding-left: 15px;
      padding-right: 15px;
    }
    .lottie-player {
      width: 40%;
      height: auto;
      right: -10px;
      top: -70px;
    }
    article {
      padding-right: 0;
      padding: 1.5rem;
    }
  }
}
@media screen and (max-width: 375px) {
  .box--modal10 {
    article {
      p {
        font-size: 18px;
        line-height: 25px;
      }
      h2 {
        font-size: 21px;
        line-height: 30px;
      }
    }
    .lottie-player {
      width: 40%;
      height: auto;
    }
  }
}