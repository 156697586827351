/* ------------------------------------------------------------
     MODAL 2
------------------------------------------------------------ */
.box--modal2 {
  position: relative;
  background: url(../../../../public/assets/images/background/back-1.png) center center no-repeat;
  background-size: cover;
  &[data-number="2"] {
    background: url(../../../../public/assets/images/background/back-2.jpg) center center no-repeat;
    background-size: cover;
  }
  &[data-number="3"] {
    background: url(../../../../public/assets/images/background/back-3.jpg) center center no-repeat;
    background-size: cover;
  }
}
.ticket {
  width: 730px;
  height: 390px;
  position: absolute;
  bottom: 20px;
  left: 32px;
  article {
    position: absolute;
    z-index: 1;
    bottom: 6%;
    left: 36%;
    text-align: left;
    transform: translate(-50%, -50%);
  }
}

.fade-in {
  opacity: 0;
  animation: fadeInAnimation 1s forwards;
  animation-delay: 1.2s; /* Retraso de 1.5 segundos */
}

@keyframes fadeInAnimation {
  to {
    opacity: 1;
  }
}


/* -----

MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
 
}
@media screen and (max-width: 1200px) {
  
}
@media screen and (max-width: 992px) {
  .ticket {
    max-width: 610px;
    height: 330px;
    article {
      position: absolute;
      bottom: initial;
      top: 50%;
      // left: 50%;
      transform: translate(-50%, 0);
    }
  }
}

@media screen and (max-width: 768px) {
  .ticket {
    width: 100%;
    height: auto;
    left: 0;
    article {
      left: 38%;
    }
    .lottie-player {
      position: relative;
      width: 100%;
      height: auto;
    }
  }
  .box--modal2 {
    &[data-number="2"] {
      background: url(../../../../public/assets/images/background/back-2-mobile.jpg) center center no-repeat;
      background-size: cover;
    }
  }
}
@media screen and (max-width: 441px) {
  .ticket {
    height: auto;
    article {
      width: 220px;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-67%,-10%);
      .h3 {
        font-size: 17px;
        line-height: 25px;
      }
      h2 {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 0;
      }
    }
    .lottie-player {
      width: 100%;
      height: auto;
    }
  }
}
@media screen and (max-width: 375px) {
  .ticket article {
    .h3 {
      font-size: 14px;
      line-height: 20px;
    }
    p{
      font-size: 14px;
      line-height: 20px;
    }
    
    h2 {
      font-size: 18px;
      line-height: 23px;
    }
  }
  
}