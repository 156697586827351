/* ------------------------------------------------------------
    INTRO SECTION
------------------------------------------------------------- */
.intro-section {
     padding-top: 120px;
     padding-bottom: 100px;
}
.intro-section__title {
     display: inline-flex;
     align-items: center;
     strong {
          font-size: 160px;
          line-height: 100px;
          margin-left: 16px;
     }
}



/* -----

 MEDIAQUERIES 

----- */
@media screen and (max-width: 1200px) {
     .intro-section {
          padding-bottom: 60px;
     }
     .intro-section__title {
          strong {
               font-size: 120px;
          }
     }
}
@media screen and (max-width: 992px) {
    
}
@media screen and (max-width: 768px) {
     .intro-section {
          padding-top: 100px;
          padding-bottom: 10px;
     }
     .intro-section__title {
         
          strong {
               font-size: 60px;
               line-height: 65px;
          }
     }
}
@media screen and (max-width: 431px) {
     .intro-section__title {
         
          strong {
               font-size: 54px;
               line-height: 60px;
          }
     }
}