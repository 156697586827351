/* ------------------------------------------------------------
     TEXT SIZE
------------------------------------------------------------- */
.text-20 {
     &,
     * {
          font-size: 20px;
          line-height: 35px;
     }
}
.text-56 {
     &,
     * {
          font-size: 56px;
          line-height: 60px;
     }
}
.text-64 {
     &,
     * {
          font-size: 64px;
          line-height: 70px;
     }
}
.text-120 {
     &,
     * {
          font-size: 120px;
          line-height: 100px;
     }
}
.text-96 {
     &,
     * {
          font-size: 96px;
          line-height: 100px;
     }
}

/* -----

 MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
   
}
@media screen and (max-width: 1200px) {
    
     .text-120,
     .text-96 {
          &,
          * {
               font-size: 80px;
               line-height: 70px;
          }
     }
     
     .text-64,
     .text-56 {
          &,
          * {
               font-size: 48px;
               line-height: 55px;
          }
     }
     
}
@media screen and (max-width: 992px) {
     .text-120,
     .text-96  {
          &,
          * {
               font-size: 60px;
               line-height: 70px;
          }
     }
}
@media screen and (max-width: 768px) {
     .text-120,
     .text-96  {
          &,
          * {
               font-size: 40px;
               line-height: 45px;
          }
     }
     .text-64,
     .text-56 {
          &,
          * {
               font-size: 32px;
               line-height: 30px;
          }
     }
}
@media screen and (max-width: 431px) {
     .text-120,
     .text-96  {
          &,
          * {
               font-size: 34px;
               line-height: 35px;
          }
     }
     .text-20 {
          &,
          * {
               font-size: 16px;
               line-height: 30px;
          }
     }
}