/* ------------------------------------------------------------
     MODAL KF
------------------------------------------------------------ */
.box--modalKF {
  background: url(../../../../public/assets/images/background/back-sky.jpg) center center no-repeat;
  background-size: cover;
  position: relative;
  article {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    z-index: 1;
    text-align: left;
    h1 {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
  .box-modal-cols {
    padding-top: 100px;
    padding-left: 60px;
  }
  .box-modal-article__priority {
    margin-bottom: 32px;
  }
  .info-row {
    max-width: 600px;
    margin: 0;
    > div {
      padding-left: 0;
      margin-bottom: 24px;
    }
  }
}


/* right plain */
.right-plain {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 430px;
  margin: 0;
  img {
    width: 100%;
  }
}
.init-plain {
  position: absolute;
  right: 0;
  bottom: 10%;
  width: 500px;
  margin: 0;
  img {
    width: 100%;
  }
}


/* -----

MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
  .right-plain {
    top: 70%;
  }
  .init-plain {
    width: 350px;
  }
}
@media screen and (max-width: 1200px) {
  
}
@media screen and (max-width: 992px) {
  .box--modalKF {
    article {
      &[data-number="4"] {
        padding-right: 30px;
        h1 {
          margin-bottom: 24px;
        }
      }
      h1 {
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }
    .lottie-player {
      top: -20%;
      width: 170%;
      height: 120%;
      left: -50%;
    }
  }

  .right-plain {
  top: 75%;
  }
  .init-plain {
    bottom: 5%;
  }
  
}
@media screen and (max-width: 768px) {
  .box--modalKF {
    background: url(../../../../public/assets/images/background/back-sky-mobile.jpg) center center no-repeat;
    background-size: cover;
    .lottie-player {
      width: 150%;
      left: -20%;
      height: 140%;
      top: -28%;
    }
    .box-modal-cols {
      padding-top: 70px;
      padding-left: 30px;
    }
    .box-modal-article__priority {
      margin-bottom: 28px;
    }
    article {
      gap: 0;
      &[data-number="4"] {
        padding-right: 0;
      }
    }
    .info-row > div {
      margin-bottom: 16px;
    }
  }
  .right-plain {
    width: 340px;
  }
  .init-plain {
    width: 90%;
  }
  
}
@media screen and (max-width: 441px) {
  .right-plain {
    top: 80%;
  }
  .box--modalKF {
    &[data-info="4"] {
      article h1 {
        line-height: 40px;
        margin-bottom: 24px;
      }
    }
    .box-modal-cols {
      padding-top: 10%;
      padding-left: 30px;
    }

    .box-modal-article__priority {
      margin-bottom: 24px;
    }
    .lottie-player {
      width: 130%;
      left: -20%;
      height: 140%;
      top: -23%;
    }
    article {
      gap: 0;
      &[data-number="4"] {
        width: 100%;
      }
      &[data-number="2"] {
        width: 100%;
      }
      &[data-number="1"] {
        width: 50%;
      }
    }
  }
  .init-plain {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 250px;
    margin: 0;
    img {
      width: 100%;
    }
  }
  .info-row {
    max-width: 600px;
    margin: 0;
    > div {
      padding-left: 0;
      margin-bottom: 16px;
    }
  }
}

@media screen and (max-width: 375px) {
  .right-plain {
    top: 80%;
  }
  .box--modalKF {
    &[data-info="4"] {
      article h1 {
        line-height: 40px;
        margin-bottom: 0px;
        font-size: 28px;
      }
    }
    &[data-info="4"] {
      article {
        width: 90%;
      }
    }
    .box-modal-article__priority {
      margin-bottom: 10px;
    }
    .lottie-player {
      width: 130%;
      left: -20%;
      height: 140%;
      top: -23%;
    }
    .info-row > div {
      margin-bottom: 0px;
    }
  }
  .init-plain {
    bottom: -10%;
    width: 250px;
  }
  .info-row {
    > div {
      margin-bottom: 0px;
    }
  }

}
