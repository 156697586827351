/* ------------------------------------------------------------
     MODAL 8
------------------------------------------------------------ */
.box--modal9 {
  background: url(../../../../public/assets/images/background/back-pattern2.svg) center center no-repeat;
  background-size: cover;
  .row {
    align-items: center;
  }
  article {
    text-align: left;
    height: 470px;
    display: flex;
    align-items: center;
    width: 60%;
  }
  .mid-title {
    margin-bottom: 32px;
  }
}
.tdc-items {
  width: 480px;
  right: -128px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  margin: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

/* -----

MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
  
  .tdc-items {
    width: 390px;
    right: -108px;
  }
  
}
@media screen and (max-width: 1200px) {
  .tdc-items {
    right: -68px;
  }
}
@media screen and (max-width: 992px) {
  .tdc-items {
    width: 240px;
    right: 2px;
  }

  .box--modal9 {
    article {
      h2,
      .h2 {
        font-size: 32px;
        line-height: 1.5;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .box--modal9 {
    .box-modal-cols {
      padding-top: 80px;
      padding-bottom: 40px;
    }
    article {
      width: 100%;
      height: auto;
      padding-top: 340px;
      text-align: center;
      justify-content: center;

      p,
      h2,
      .h2 {
        font-size: 24px;
      }
    }
    
  }
  .tdc-items {
    width: 320px;
    right: initial;
    left: 50%; 
    transform: translateX(-50%);
    top: -20px;
  }
}
@media screen and (max-width: 441px) {
  .box--modal9 {
    &[data-info="2"] {
      h2,
      .h2 {
        font-size: 18px;
        line-height: 30px;
      }
    }
    article {
      padding-top: 240px;
      padding-left: 12px;
      padding-right: 12px;
      h2,
      .h2 {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 0;

        &.mid-title {
          margin-bottom: 0;
        }
      }
    }
    .box-modal-cols {
      padding: 60px 30px 30px 30px;
    }
  }
  .tdc-items {
    top: -20px;
    width: 280px;
    height: 280px;
  }
}
@media screen and (max-width: 375px) {
  .tdc-items {
    width: 230px;
  }
  .box--modal9 article {
    // padding-top: 290px;
    p {
      font-size: 18px;
      line-height: 25px;
    }
    h2 {
      font-size: 21px;
      line-height: 30px;
    }
  }
}