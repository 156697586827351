/* ------------------------------------------------------------
     COUNTER
------------------------------------------------------------ */
.counter {
     color: $c-Green150;
     font-family: $c-FontBold;
     font-size: 80px;
     line-height: 0;
     position: relative;
     top: 4px;
     &.color--white {
          color: #fff;
     }
     &.color--green-dark {
          color: $c-Green800;
     }
     &.color--green {
          color: $c-Green600;
     }
     &.size-60 {
          font-size: 60px;
          line-height: 60px;
     }
}


/* -----

 MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
   
}
@media screen and (max-width: 1200px) {
    
}
@media screen and (max-width: 820px) {
     .counter {
          font-size: 60px;
          line-height: 60px;
     }
}
@media screen and (max-width: 680px) {
   
}