/* ------------------------------------------------------------
     MODAL 1
------------------------------------------------------------ */
.box--modal1 {
  background: $c-Green700;
  .row {
    align-items: center;
  }
  &.title--no-padding {
    .box-modal-article {
      h2 {
        margin: 0;
      }
    }
  }
  .box-modal-article {
    padding: 0 32px;
    h2 {
      margin: 40px 0;
    }
  }
}
.box-modal-article__priority {
  margin-bottom: 60px;
}


/* -----

MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
  .box--modal1 {
    &:not([data-extra="true"]) {
      .lottie-player {
        display: flex;
        align-items: center;
        justify-content: center;
        div {
          width: 350px !important;
          height: 350px !important;
        }
      }
    }
    
  }
}
@media screen and (max-width: 1200px) {
  .box--modal1 {
    .box-modal-article {
      h2 {
        margin: 24px 0;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .box--modal1 {
    &[data-extra="true"] {
      h2 {
        margin: 0;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .box--modal1 {
    &[data-extra="true"] {
      .row {
        .box-modal-cols {
          &:first-child {
            height: 60%;
          }
          &:last-child {
            height: 40%;
          }
        }
      }
      h2 {
        margin-bottom: 16px;
      }
      .lottie-player {
        width: 130%;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .row {
      .box-modal-cols {
        &:first-child {
          height: 60%;
        }
        &:last-child {
          height: 40%;
        }
      }
    }
    .box-modal-article {
      text-align: center;
      padding: 0;
      h2 {
        margin: 8px 0 24px 0;
        // font-size: 56px;
        font-size: 32px;
        line-height: 60px;
      }
    }
  }
}
@media screen and (max-width: 441px) {
  .box--modal1 {
    
    .row {
      .box-modal-cols {
        &:first-child {
          height: 70%;
        }
        &:last-child {
          height: 30%;
        }
      }
    }
    .box-modal-article {
      h2 {
        font-size: 32px;
        line-height: 32px;
        margin-bottom: .375rem;
      }
      .h2 {
        font-size: 20px;
        line-height: 35px;
      }
      .h3 {
        font-size: 16px;
        line-height: 25px;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  
}
