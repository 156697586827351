/* ------------------------------------------------------------
     LOTTIE
------------------------------------------------------------- */
.lottie-player {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     pointer-events: none;
     > div {
          overflow: visible !important;
          svg {
              overflow: visible !important; 
          }
     }
}



/* -----

 MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
   
}
@media screen and (max-width: 1200px) {
    
}
@media screen and (max-width: 992px) {
    
}
@media screen and (max-width: 680px) {
  
}