.share-component{
  display: flex;
  flex-direction: column;
  height: 800px;
  max-width: 500px;
  width: 100%;
}
.footer-share-component{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $c-Green800;
  padding-bottom: 2.5rem;
  width: 100%;
  height: auto;
  svg,
  img{
    margin-top: 0;
    margin-bottom: 1.5rem;
    display: flex;
    width:15%;
    height: auto;
  }
}

.lottie-container-share{
  height: 55%;
  width: 100%;
  display: flex;

  svg,
  img{
    width: 100%;
    height: 100%;
    display: block;
  }
}

.body-container{
  height: 40%;
  background-color: $c-Green800;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 3rem;
  .header-share{
    display: flex;
    .header-text{
      color: #FFF;
      text-align: center;
      font-family: $c-FontRegular;
      font-size: 1.5rem;
      font-style: normal;
  font-family: $c-FontBold;
      line-height: normal;
    }
  }
  .footer-share{
    display: flex;

  }
  .text-container-primary{
    display: flex;
    flex-direction: row;
  }
  .text-container-secondary{
    display: flex;
  }
}

.content-text{
  display: flex;
  flex-direction: column;
  line-height: .8rem;
  text-align: center;
  font-family: $c-FontRegular;
  .text-primary-content{
    color: #FFF;
  }
  .text-secondary-content{
    color: $c-Green150;
    font-size: 1.5rem;
    font-family: $c-FontBold;
  }
}
.footer-share-component {
  margin-bottom: 0;  
}
.share-component {
  p {
    &:first-child {
      margin-bottom: 12px;
    }
  }
  .text-container-primary {
    margin-bottom: 16px;
    margin-top: 16px;
  }
}

@media screen and (max-width: 768px) {
  .share-component {
    height: auto;
    min-height: 443px;
  }
}

@media screen and (max-width: 441px) {
  .body-container{
    padding: 24px 15px;
    height: 35%;
    p {
      &:first-child {
        margin-bottom: 8px;
      }
    }
    .text-container-primary {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .lottie-container-share{
    height: auto;
    width: 100%;
    display: flex;
    object-fit: contain;
    svg,
    img{
      max-width: 100%;
      height: auto;
      display: flex;
    }
  }
  .footer-share-component{
    width: 100%;
    height: auto;
    svg,
    img{
      margin-top: 0rem;
      margin-bottom: 0rem;
      display: flex;
      width: 20%;
      height: auto;
      max-width: 150px;
    }
  }
}