/* ------------------------------------------------------------
     ITEM BOX
------------------------------------------------------------ */
.item-box {
     width: 100%;
     height: 100%;
     position: relative;
     figure {
          margin: 0;
          img {
               width: 100%;
          }
     }
}
.item-box--1 {
     background: $c-Green300;
     display: flex;
     align-items: flex-end;
     justify-content: center;
     .item-box__number {
          width: 110px;
          height: 110px;
          border-radius: 50%;
          background: #fff;
          position: absolute;
          top: 10px;
          right: 10px;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
     }
     figure {
          width: 80%;
         
     }
}
.item-box__number {
     font-size: 72px;
     line-height: 70px;
     font-family: $c-FontBold;
     color: $c-Green700;
}
.item-box--2 {
     background: $c-Green350;
     figure {
          position: absolute;
          left: 50%;
          top: 60%;
          transform: translate(-50%, -50%);
          width: 180px;
     }
     .item-box__number {
          position: absolute;
          left: 66%;
          top: 55%;
          transform: translate(-50%, -50%);
          z-index: 1;
     }
}
.item-box--3 {
     background: $c-Green700;
     figure {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 220px;
     }
     .item-box__number {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
     }
}
.item-box--4 {
     figure {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
     }
     .item-box__number {
          position: absolute;
          left: 50%;
          top: 35%;
          transform: translate(-50%, -50%);
          z-index: 1;
     }
}
.item-box--5 {
     figure {
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          img {
               width: 100%;
               height: 100%;
               object-fit: cover;
               object-position: center;
          }
     }
     .item-box__number {
          position: absolute;
          left: 30px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 1;
          background:url(../../../../public/assets/images/grid/star.svg)  center center no-repeat;
          width: 184px;
          height: 184px;
          background-size: contain;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
     }
}

.item-box--6 {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 28px;
	background: $c-Green700;
	figure {
		@include absolute-fullsize;
		margin: 0;
		img {
			@include relative-fullsize-image;
		}
	}

	.item-box__number {
		position: relative;
		top: 0;
		right: 0;
		background: #fff;
		width: 120px;
		height: 120px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $c-Green500;
	}

	* {
			 margin: 0 4px;
	}
}

.item-box__number_variant {
	color: $c-Green300;
	position: absolute;
	top: 25%;
	right: 10%;
	z-index: 1;
	font-size: 62px;
	font-family: $c-FontBold;
     background-color: initial;
}

.item-box--7 {
     background: $c-Green500;
     figure {
          width: 220px;
          height: 220px;
          @include absolute-center;

          img {
               display: block;
               height: 100%; 
          }
     }
     .item-box__number {
          color: #fff;
          @include absolute-center;
          z-index: 1;
     }
}
.item-box--8 {
     figure {
          @include absolute-fullsize;
          img {
            @include relative-fullsize-image;   
          }
     }
     .item-box__number {
          color: $c-Green350;
          width: 160px;
          height: 160px;
          background:url(../../../../public/assets/images/grid/circle.svg)  center center no-repeat;
          background-size: contain;
          @include absolute-center;
          top: 60%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;
     }
}
.item-box--9 {
     figure {
          @include absolute-fullsize;
          img {
            @include relative-fullsize-image;   
          }
     }
     .item-box__number {
          color: $c-Green700;
          width: 200px;
          height: 200px;
          background:url(../../../../public/assets/images/grid/rombo.svg)  center center no-repeat;
          background-size: contain;
          @include absolute-center;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;
     }
}
.item-box--10 {
     background: $c-Green500;
     figure {
          @include absolute-center;
          width: 100%;
          height: 100%;
          padding: 12px;
          img {
            @include relative-fullsize-image;
            object-fit: contain;
          }
     }
     .item-box__number {
          color: $c-Green700;
          width: 150px;
          height: 150px;
          background:url(../../../../public/assets/images/grid/cuadrado.svg)  center center no-repeat;
          background-size: contain;
          @include absolute-center;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;
     }
}
.item-box--11 {
	background: $c-Green700;

	.item-box__number {
		color: $c-Green300;
		position: absolute;
		left: 32px;
		top: 100px;
		z-index: 2;

		&.center {
			top: 50%;
			left: 50%;
			transform: translate(-40%, -20%);
			color: white;
		}
	}

	.item-image {
		position: relative;
		display: flex;
		width: 100%;
		height: 100%;
		z-index: 1;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: center center; 
		}
	}
}

.item-box--generic {
	position: relative;
	background: $c-Green700;
	display: flex;

	.item-box__number {
		position: absolute;
		color: $c-Green300;
		left: 32px;
		top: 100px;
		z-index: 2;

		&.center {
			top: 50%;
			left: 50%;
			transform: translate(-40%, -20%);
			color: white;
		}

		&.right {
			left: initial;
			top: 18%;
			right: 18%;
			color: white;

			&.large {
				top: 10%;
				right: 10%;
			}
		}

		&.left {
			top: initial;
			bottom: 15%;
			left: 18%;
			color: white;

			&.large {
				bottom: 10%;
				left: 10%;
			}
		}
	}

	.item-image {
		position: relative;
		display: flex;
		width: 100%;
		height: 100%;
		z-index: 1;

		img {
			display: flex;
			flex: 1;
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: center center;

			&.contain {
				object-fit: contain;
			}
		}
	}
}

.item-faceid{
     width: 260px; 
     position: absolute;
     left: 50%;
     transform: translateX(-50%);
     top: 100px;
     img {
          width: 100%;
     }
}
.item-smile-logo {
     width: 190px; 
     position: absolute;
     bottom: 32px;
     left: 50%;
     transform: translateX(-50%);
     img {
          width: 100%;
     }
}
.item-box--12 {
     background: $c-Green300;
   
    figure {
          width: 85%;
          bottom: 0;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
    }
    .item-box__number  {
     display: none;
    }
}
.box--12-mobile {
     display: none;
}

.dot,
.dot2 {
	display: block;
	width: 28%;
	height: auto;
	object-fit: contain;
	object-position: center;
}

/* -----

 MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
     .item-box__number {
          font-size: 62px;
          line-height: 65px;
     }
     .item-box--1 .item-box__number {
          width: 90px;
          height: 90px;
          top: 20px;
          right: 20px;
     }
     .item-box--4 .item-box__number {
          top: 44%;
     }
     .item-faceid {
          width: 220px;
     }
}
@media screen and (max-width: 1200px) {
     .item-box__number,
     .item-box__number_variant {
          font-size: 52px;
          line-height: 55px;
     }
     .item-box--2 figure {
          width: 130px;
     }
     .item-box--3 figure {
          width: 90%;
     }
     .item-box--4 .item-box__number {
          top: 35%;
      }
      .item-box--5 .item-box__number {
          width: 150px;
          height: 150px;
      }
      .item-box--7 figure {
          width: 100%;
          height: 100%;
          padding: 10px;
      }
      .item-box--8 .item-box__number {
          width: 100px;
          height: 100px;
     }
     .item-box--9 .item-box__number {
          width: 130px;
          height: 130px;
     }
     .item-box--10 .item-box__number {
          width: 120px;
          height: 120px;
     }
     .item-box--11 .item-box__number {
          top: 80px;
     }
     .item-faceid {
          top: 80px;
          width: 150px;
     }
     .item-smile-logo {
          width: 130px;
     }
}
@media screen and (max-width: 1024px) {
     .dot,
     .dot2 {
          display: block;
          width: 28%;
          height: auto;
     }
     .item-box--6  {
          .item-box__number {
               top: 22px;
               right: 22px;
          }
      }
      .item-box--6 {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 28px;
          .item-box__number {
               position: relative;
               top: 0;
               right: 0;
               background: #fff;
               width: 100px;
               height: 100px;
               border-radius: 50%;
               display: flex;
               justify-content: center;
               align-items: center;
               color: $c-Green500;
          }
          * {
               margin: 0 4px;
          }
     }
}
@media screen and (max-width: 992px) {
     .item-box__number,
		 .item-box__number_variant {
          font-size: 42px;
          line-height: 50px;
      }
      .item-box--1 .item-box__number {
          width: 70px;
          height: 70px;
      }
      .item-box--2 figure {
          width: 100px;
      }
      .item-box--4 .item-box__number {
          top: 40%;
      }
      .item-box--5 .item-box__number {
          width: 110px;
          height: 110px;
      }
      .item-box--8 .item-box__number {
          width: 80px;
          height: 80px;
      }
      
      .item-box--11 .item-box__number {
         top: 50px;
         left: 16px;
      }
      .item-faceid {
          top: 50px;
          width: 130px;
      }
     .dot,
     .dot2 {
          width: 28%;
          height: auto;
     }
     .item-box--6 .item-box__number {
          width: 70px;
          height: 70px;
     }
     .item-box--6 .item-box__number_variant {
        top: 15%;
        right: 5%;
     }
}
@media screen and (max-width: 768px) {
     .item-box__number,
     .item-box__number_variant {
          font-size: 24px;
          line-height: 30px;
     }
     .item-box--1 .item-box__number {
          width: 40px;
          height: 40px;
          right: 10px;
          top: 10px;
      }
      .item-box--2 figure {
          width: 60px;
      }
      .item-box--4 .item-box__number {
          top: 36%;
      }
      .item-box--5 .item-box__number {
          width: 70px;
          height: 70px;
      }
      .item-box--6 .item-box__number {
          width: 50px;
          height: 50px;
     }
     
      .item-box--8 .item-box__number {
          width: 50px;
          height: 50px;
      }
      .item-box--9 .item-box__number {
          width: 70px;
          height: 70px;
      }
      .item-box--10  {
          .item-box__number {
               width: 60px;
               height: 60px;
           }
          figure {
               width: 100%;
               height: 100%;
               padding: 5px;
          }
      }
      .item-faceid {
          width: 90px;
          top: 40px;
      }
      .item-box--11 .item-box__number {
          top: 40px;
      }
      .item-smile-logo {
          bottom: 10px;
          width: 80px;
      }
      
}
@media screen and (max-width: 431px) {
     .item-box--1 .item-box__number {
          background: transparent;
          top: 0;
          right: 0;
     }
     .item-box--2 figure {
          width: 80px;
      }
      .item-box--4 figure {
          width: 119px;
          position: absolute;
          bottom: -20px;
          left: 50%;
          transform: translateX(-50%);
      }
      .item-box--4 .item-box__number {
          top: 45%;
          font-size: 18px;
      }
      .item-box--5 .item-box__number {
          width: 60px;
          height: 60px;
          left: 10px;
          top: initial;
          transform: initial;
          bottom: 10px;
      }
     
     .item-box--8 .item-box__number {
          width: 60px;
          height: 60px;
          top: 50%;
      }
      .item-box--9 .item-box__number {
          background:url(../../../../public/assets/images/grid/rombo-simple.svg)  center center no-repeat;
          background-size: contain;
      }
      .item-box--10 .item-box__number {
          width: 60px;
          height: 60px;
      }
      .item-box--10 figure {
          width:100%;
          height: 100%;
          img {
               object-fit: contain;
          }
      }
      .item-faceid {
          width: 44px;
          top: 20px;
      }
      .item-box--11 .item-box__number {
          top: 19px;
          left: 5px;

					&.center {
						top: 50%;
						left: 50%;
						transform: translate(-40%, -32%);
						color: white;
					}
      } 
      .item-smile-logo {
          bottom: 6px;
          width: 50px;
      }
     .box--12-mobile {
          display: block;
     }
     .box--12-desktop {
          display: none;
     }
     .item-box--12 {
          figure {
               width: 110px;
               padding-left: 20px;
               left: initial;
               transform: initial;
               right: 0;
           }
           .item-box__number {
               display: block;
               color: #fff;
               position: absolute;
               top: 36.5%;
               left: 51px;
               z-index: 1;
               transform: translateY(-50%);
               z-index: 1;
               font-size: 17px;
           }
     }
     .dot,
     .dot2 {
          width: 40px;
     }
     .dot2 {
          display: none;
     }
     .item-box--6 .item-box__number {
          width: 40px;
          height: 40px;
     }
}
@media screen and (max-width: 375px) {
     .item-box--4 .item-box__number {
          top: 47%;
     }
     .item-box--12 .item-box__number {
          top: 44px;
     }
     .item-faceid {
          width: 40px;
     }
}