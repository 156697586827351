/* ------------------------------------------------------------
    TOUR
------------------------------------------------------------- */
.tour-container {
     position: relative;
     z-index: 9999999;
}
.tour-build {
     position: fixed;
     background: rgba(0, 0, 0, 0.40);
     top: 0;
     left: 0;
     z-index: 9999999;
     width: 100%;
     height: 100%;
     figure {
          width: 140px;
          margin: 0;
          position: absolute;
          top: 53%;
          left: 27%;
          transform: translate(-50%, -50%);
          animation: pulse 2s infinite; 
          filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
          img {
               margin: 0;
               width: 100%;
          }
     }
}
.tour-section{
     &.active {
          animation: init 2s ; 
          transition: .4s all ease-in-out;
     }
    
}


// Definición de la animación
@keyframes pulse {
     0% {
          transform: translate(-50%, -50%) scale(1);
     }
     50% {
          transform: translate(-50%, -50%) scale(1.1);
     }
     100% {
          transform: translate(-50%, -50%) scale(1);
     }
}




/* -----

 MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
   
}
@media screen and (max-width: 1200px) {
     .tour-build figure {
          top: 43%;
     }
     
}
@media screen and (max-width: 992px) {
     .tour-build figure {
          top: 41%;
      }
}
@media screen and (max-width: 768px) {
     .tour-build figure {
          width: 80px;
          top: 27%;
          left: 25%;
     }
}
@media screen and (max-width: 431px) {
     .tour-build figure {
          top: 37%;
          left: 22%;
      }
}