/* ------------------------------------------------------------
     HEADER
------------------------------------------------------------ */
header {
     width: 100%;
     background: #fff;
     padding: 20px 0;
     position: absolute;
     top: 0; 
     left: 0;
     z-index: 2;
}
.header__inner {
     width: 100%;
    
}
.header__logo {
     width: 110px;
     margin: 0;
     display: block
     img {
          width: 100%;
     }
}

/* -----

 MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
   
}
@media screen and (max-width: 1200px) {
    
}
@media screen and (max-width: 820px) {
     header {
          padding: 10px 0;
     }
     .header__logo {
          width: 100px;
      }
}
@media screen and (max-width: 680px) {
   
}