/* ------------------------------------------------------------
     SWAL
------------------------------------------------------------- */
.swal2-popup {
     border-radius: 16px;
     &.swal-instagram-container {
          padding: 0;
          .swal2-html-container {
               border-radius: 8px;
               overflow: hidden;
          }
     }
     .swal2-title {
          color: $c-Base;
          font-size: 21px;
     }
     .swal2-actions,
     .swal2-html-container {
          button {
               width: 100%;
               font-family: $c-FontBold;
               width: 300px;
               height: 60px;
               line-height: 60px;
               font-size: 16px;
               text-align: center;
               padding: 0;
               border-radius: 12px;
               margin-bottom: 16px;
               border: 0 !important;
               box-shadow: initial !important;
               &:last-child {
                    margin-bottom: 0;
               }
          }
     }
     .swal2-html-container {
          margin: 0;
          &,
          > section {
               height: 100%;
               width: 100%;
          }
     }
     .swal2-cancel {
          color: $c-Green800;
          background: transparent;
          margin: 0;
          text-decoration: underline;
     }
     .swal2-deny {
          background: $c-Green400;
          &:hover {
               background: $c-Green400;
          }
     }
     .swal2-confirm {
          background: $c-Red400;
          color: #fff;
          
          &:hover {
               background: $c-Red400;
               color: #fff;
          }
     }
}
.swal2-popup {
     &.swal-instagram-container {
          width: 100%;
          max-width: 1240px;
          height: 650px;
          align-items: center;
       
          .swal2-actions {
               display: flex;
               justify-content: center;
               button {
                    &.swal2-confirm {
                         order: 2;
                    }
               }
          }
          .step-change {
               button {
                    background: $c-Green300;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 2;
                    margin: 0 !important; 
                    &#prevButton {
                         left: -70px;
                    }
                    &#nextButton {
                         right: -70px;
                    }
                    &:hover {
                         opacity: .7;
                    }
                    &:disabled {
                         opacity: .2;
                    }
                    i {
                         color: $c-Green700;
                    }
               }
          }
          
          #closeButton {
               position: absolute;
               left: 0;
               top: -60px;
               padding: 0;
               height: auto;
               width: auto;
               background: transparent;
               color: #fff;
               font-weight: 600;
               display: flex;
               align-items: center;
               i {
                    margin-right: 8px;
               }
          }
     }
}
div:where(.swal2-container).swal2-backdrop-show, div:where(.swal2-container).swal2-noanimation {
     background: rgba(0, 0, 0, 0.80);
}

.swal-instagram-container {
     width: 100%;
     max-width: 1240px;
    
     .modal-content {
         padding: 0;
         height: 650px;
         background: transparent;
         .modal-body {
          padding: 0;
               > section {
                    height: 100%;
               }
         }
     }
     .box-swal {
          border-radius: 8px;
          overflow: hidden;
     }
     .step-change {
          button {
               background: $c-Green300;
               width: 40px;
               height: 40px;
               border-radius: 50%;
               position: absolute;
               top: 50%;
               transform: translateY(-50%);
               display: flex;
               align-items: center;
               justify-content: center;
               border: 0;
               z-index: 2;
               margin: 0 !important;
               &#prevButton {
                    left: -70px;
               }
               &#nextButton {
                    right: -70px;
               }
               &:hover {
                    opacity: .7;
               }
               &:disabled {
                    opacity: .2;
               }
               i {
                    color: $c-Green700;
               }
          }
     }
     #closeButton {
          position: absolute;
          left: 0;
          top: -30px;
          padding: 0;
          height: auto;
          width: auto;
          background: transparent;
          color: #fff;
          font-weight: 600;
          display: flex;
          align-items: center;
          border: 0;
          i {
               margin-right: 8px;
          }
     }
}

/* stepper */
.stepper {
     width: 100%;
     background: linear-gradient(181deg, rgba(0, 0, 0, 0.5) -50.69%, rgba(0, 0, 0, 0) 95.23%);
     display: flex;
     justify-content: space-between;
     position: absolute;
     left: 0;
     top: 0;
     z-index: 1;
     padding: 16px 16px 24px 16px;
     border-radius: 8px 8px 0 0;
     .step {
          position: relative;
          flex: 1;
          text-align: left;
          margin-right: 3px;
          color: #fff;
          &.active {
               .progress-bar-container {
                    .progress-bar {
                         display: block;
                    }
               } 
          }
          &.viewed {
               .progress-bar-container {
                    overflow: hidden;
                    height: 4px;
                    top: -2px;
                    border-radius: 10px;
                    .progress-bar {
                         display: block !important;
                         width: 300px !important;
                         transition: inherit !important;
                         position: relative;
                         top:0 ;
                         border-radius: 0;
                    }
               } 
          }
          .progress-bar-container {
               .progress-bar {
                    display: none;
               }
          }
     }
}
.progress-bar-container {
     position: absolute;
     bottom: 0;
     width: 100%;
     height: 1px;
     background: #fff;
}
.progress-bar {
     background: $c-Green300;
     height: 4px;
     border-radius: 10px;
     width: 0;
     position: relative;
     top: -1px
}

/* box modal */
.box-swal {
     width: 100%;
     height: 100%;
     .row {
          height: 100%;
          margin: 0;
     }
}
.box-modal-article {
     text-align: left;
     *:last-child {
          margin-bottom: 0;
     }
}
.col--color1 {
     background: $c-Green500;
}
.col--color2 {
     background: $c-Green300;
}
.col--lottie {
     position: relative;
     height: 100%;
}
.col-vertical-center {
     display: flex;
     align-items: center;
     justify-content: center;
}

/* modal */
.modal {
     z-index: 999999999;
}
.modal-backdrop {
     z-index: 99999999;
}

/* -----

 MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
     .modal-dialog.swal-instagram-container {
          max-width: 85%;
     }
}
@media screen and (max-width: 1024px) {
     .modal-dialog.swal-instagram-container {
          .step-change button {
               width: 210px;
               height: 100%;
               opacity: 0 !important;
               &#nextButton {
                    right: 0;
               }
               &#prevButton {
                    left: 0;
               }
          }
     }
}
@media screen and (max-width: 992px) {
     .modal-dialog.swal-instagram-container {
         max-width: 100%;
          // height: 100%;
          height: 100dvh;
          position: fixed;
          .modal-content {
               max-width: 90%;
               margin: 0 auto;
          }
         
     }
     .swal-instagram-container .step-change button {
          opacity: 1 !important;
     }
}
@media screen and (max-width: 768px) {
     .modal-dialog.swal-instagram-container {
          width: 80%;
          left: 10%;
          margin: 0;
         
          .modal-content {
               height: 80vh;
          }
         
          
          #closeButton {
               top: -20px;
               font-size: 12px;
               height: 16px;
               width: 100px;
          }
         
     }
}
@media screen and (max-width: 431px) {
     .modal-dialog.swal-instagram-container {
          width: 100%;
          left: 0%;
     }
}