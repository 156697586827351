/* ------------------------------------------------------------
     MODAL BN
------------------------------------------------------------ */
.box--modalBN {
  position: relative;
  overflow: hidden;
  background: url(../../../../public/assets/images/background/back-sky.jpg) center center no-repeat;
  background-size: cover;
  .row {
    align-items: center;
  }
  .box-modal-cols {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lottie-player {
    display: flex;
    width: 331px;
    height: 640px;
    right: -110px;
    top: -120px;
    left: initial;

    svg {
      overflow: visible;
    }
  }
}
.box-green {
  position: relative;
  background: $c-Green700;
  border-radius: 30px;
  height: 408px;
  max-width: 700px;
  width: 100%;
  text-align: left;
  padding: 32px;
  display: flex;
  align-items: center;
  padding-right: 20%;
  h2 {
    margin-bottom: 32px;
  }
  .bundle-title{
    font-size: 32px;
  }
  .bundle-subtitle{
    font-size: 30px;
  }
}


/* -----

MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
  
}
@media screen and (max-width: 1200px) {
  .box-green {
    padding-right: 30%;
  }

  .box--modalBN .lottie-player {
    width: 311px;
    height: 600px;
    right: -40px;
  }
}
@media screen and (max-width: 1024px) {
  .box--modalBN .lottie-player {
    width: 281px;
    height: 490px;
  }
}
@media screen and (max-width: 992px) {
  .box-green {
    padding-right: 40%;
  }
  
}
@media screen and (max-width: 768px) {
  .box--modalBN {
    background: url(../../../../public/assets/images/background/back-sky-mobile.jpg) center center no-repeat;
    background-size: cover;
    .lottie-player {
      width: 311px;
      height: 500px;
      right: initial;
      left: 50%;
      transform: translateX(-50%);
      top: -410px;
    }
  }
  .box-green {
    padding-right: 0;
    padding-top: 120px;
    text-align: center;
    justify-content: center;
    height: auto;
    padding-bottom: 70px;
    position: absolute;
    bottom: 10%;
    max-width: 90%;
    h2 {
      margin-bottom: 8px;
    }
  }
}
@media screen and (max-width: 441px) {
  .box--modalBN {
    .lottie-player {
      width: 70%;
      height: auto;
      top: -327px;
      // padding: 3rem;
    }
  }
  .box-green {
    padding-left: 15px;
    padding-right: 15px;
    bottom: 5%;
    padding-bottom: 30px;
    padding-top: 30px;
    .bundle-title{
      font-size: 24px;
    }
    .bundle-subtitle{
      font-size: 18px;
    }
    p {
      font-size: 20px;
    }
    h2{
      font-size: 18px;
      line-height: 25px;
    }
    .h3{
      line-height: 25px;
    }
  }
}

@media screen and (max-width: 375px) {
  .box--modalBN {
    .lottie-player {
      width: 70%;
      height: auto;
      top: -295px;
      padding: .5rem;
    }
  }
  .box-green {
    padding-left: 15px;
    padding-right: 15px;
    bottom: 5%;
    padding-bottom: 20px;
    padding-top: 20px;
  }
}