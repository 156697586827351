/* ------------------------------------------------------------
     MODAL 5
------------------------------------------------------------ */
.box--modal5 {
  background: $c-Green500;
  .row {
    align-items: center;
  }
  .box-modal-cols {
    &:first-child {
      padding: 0 40px;
    }
  }
  .col--lottie {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    &[data-lottie="2"],
    &[data-lottie="4"] {
      .lottie-player {
        left: 0;
      } 
    }
    .lottie-player {
      display: flex;
      width: 440px;
      height: 410px;
      position: relative;
      left: 15%;
    }
  }
}

.box-modal5__image {
  width: 400px;
  margin: 0;
  img {
    width: 100%;
  }
}



/* -----

MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
 
}
@media screen and (max-width: 1024px) {
  .box--modal5 {
    &[data-info="4"] {
      .box-modal-article {
        .h2 {
          font-size: 28px;
          line-height: 35px;
        }
        .text-64 {
          font-size: 40px;
          line-height: 45px;
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .box--modal5 .col--lottie .lottie-player {
    width: 340px;
    height: 290px;
  }
}
@media screen and (max-width: 768px) {
  .box--modal5 {
    .row {
      .box-modal-cols {
        &:first-child {
          order: 2;
          height: 40%;
        }        
        &:last-child {
          order: 1;
          height: 60%;
        }
      }
      .col--lottie {
        padding-left: 15px;
        &[data-lottie="2"],
        &[data-lottie="4"] {
          .lottie-player {
            left: 3%;
            width: 450px;
            height: 310px;
          }
        }
        .lottie-player {
          left: 13%;
        }
      }
    }
    
    .box-modal-article {
      text-align: center;
      padding: 0;
      h2 {
        font-size: 56px;
        line-height: 60px;
      }
    }
    .box-modal-article__priority {
      margin-bottom: 16px;
    }
  }
  .box-modal5__image {
    width: 260px;
  }
}
@media screen and (max-width: 441px) {
  .box--modal5 {
    &[data-info="4"] .box-modal-article {
      h2,
      .h2 {
        font-size: 20px;
        line-height: 35px;
      }
      .h3 {
        font-size: 16px;
        line-height: 25px;
      }
      .text-64 {
        font-size: 28px;
        line-height: 35px;
      }
    }
    .row {
      .box-modal-cols {
        &:first-child {
          height: 35%;
          padding: 0 15px;
        }        
        &:last-child {
          height: 65%;
        }
      }
      .col--lottie {
        padding-left: 15px;
        &[data-lottie="1"]{
          .lottie-player {
            height: auto;
            width: 100%;
            left: 15%;
          }
        }
        &[data-lottie="2"],
        &[data-lottie="4"] {
          .lottie-player {
            left: 3%;
            width: 390px;
            height: auto;
          }
        }
        .lottie-player {
          left: 12%;
          height: 340px;
          width: 100%;
        }
      }
    }
   
    .box-modal-article {
      h2 {
        font-size: 32px;
        line-height: 32px;
      }
      .h2 {
        font-size: 20px;
        line-height: 35px;
      }
      .h3 {
        font-size: 16px;
        line-height: 25px;
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .box--modal5 {
    .row {
      .box-modal-cols {
        &:first-child {
          height: 35%;
          padding: 0 16px;
        }        
        &:last-child {
          height: 65%;
        }
      }
      
    }
    .box-modal-article {
      p {
        margin-bottom: 0;
      }
    }
    .box-modal-article__priority {
      margin-bottom: 8px;
    }
  }
}
