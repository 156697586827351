/* ------------------------------------------------------------
    SMALL
------------------------------------------------------------ */
@media screen and (max-width:680px) {
    .text-24 {
        font-size: 16px;
    }
}
@media screen and (max-width:580px) {
   
}
@media screen and (max-width:480px) {
  
}
@media screen and (max-width:380px) {
    
}