/* ------------------------------------------------------------
     BUTTON
------------------------------------------------------------ */
a {
     color: $c-Green300;
}
.btn {
     display: inline-block;
     border: 0;
     &:disabled {
          background: $c-Red400;
          opacity: 1;
          pointer-events: none;
          color: #fff;
          border: 0;
     }
}
.btn--type1 {
     background: $c-Red400;
     color: #fff !important;
     font-family: $c-FontBold;
     width: auto;
     height: 40px;
     line-height: 40px;
     font-size: 16px;
     text-align: center;
     padding: 0 32px;
     border-radius: 12px;
     border: 0 !important;
     opacity: 1 !important;
     &:hover {
          opacity: 1 !important;
          background: $c-Red500;
          color: #fff;
          border: 0;
     }
}

/* -----

 MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
   
}
@media screen and (max-width: 1200px) {
    
}
@media screen and (max-width: 992px) {
    
}
@media screen and (max-width: 680px) {
   
}