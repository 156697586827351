/* ------------------------------------------------------------
     MODAL 7
------------------------------------------------------------ */
.box--modal7 {
  background: url(../../../../public/assets/images/background/back-colors.jpg) center center no-repeat;
  background-size: cover;
  .row {
    align-items: center;
  }
  .col--lottie {
    display: flex;
    justify-content: center;
    align-items: center;
    .lottie-player {
      width: 80%;
      height: 80%;
      position: relative;
    }
  }
  .box-modal-article {
    padding: 0;
    width: 100%;
    .h3 {
      &:first-child {
        margin-bottom: 32px;
      }
    }
    h2 {
      margin: 16px 0;
    }
  }
}


/* -----

MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
  .box--modal7 {
    .lottie-player {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      div {
        width: 350px !important;
        height: 350px !important;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .box--modal7 {
    .box-modal-article {
      h2 {
        margin: 8px 0;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .box--modal7 .lottie-player div {
      width: 340px !important;
      height: 280px !important;
  }
}
@media screen and (max-width: 768px) {
  .box--modal7 {
    background: url(../../../../public/assets/images/background/back-colors-mobile.jpg) center center no-repeat;
    background-size: cover;
    background-position: top right;
    .col--lottie {
      height: auto !important;
      .lottie-player {
        align-items: start;
        height: auto !important;
        width: 60% !important;
        div {
          height: auto !important;
        }
        svg {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
    .initial-title {
      margin-bottom: 60px;
    }
    .row {
      align-items: start;
      .box-modal-cols {
        &:first-child {
          height: 60%;
          padding-top: 30px;
        }
        &:last-child {
          height: 40%;
          align-items: flex-start;
        }
      }
    }
    .lottie-player div {
        width: 340px !important;
        height: 430px !important;
    }
    .box-modal-article {
      text-align: center;
      padding: 0;
      h2 {
        margin: 8px 0;
        font-size: 56px;
        line-height: 60px;
      }
    }
  }
}
@media screen and (max-width: 441px) {
  .box--modal7 {
    .col--lottie {
      .lottie-player {
        width: 90% !important;
        div {
          width: 75% !important;
          height: auto !important;
        }
        svg {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
  }

  .box--modal7 {
    .initial-title {
      margin-bottom: 10px;
    }
    .col--lottie  .lottie-player {
      width: 290px;
      height: 200px;
    }
    .row {
      .box-modal-cols {
        &:first-child {
          height: calc(40% - env(safe-area-inset-top));
        }
        &:last-child {
          height: calc(60% - env(safe-area-inset-bottom));
        }
      }
    }
    .box-modal-article {
      .h3 {
        &:first-child {
          margin-bottom: .5rem;
        }
      }
      h2 {
        font-size: 32px;
        line-height: 32px;
      }
      .h2 {
        font-size: 20px;
        line-height: 35px;
      }
      .h3 {
        font-size: 16px;
        line-height: 25px;
      }
    }
  }
}

@media screen and (max-width: 431px) {
  .box--modal7 {
    .col--lottie {
      .lottie-player {
        width: 100% !important;
        div {
          width: 65% !important;
          height: auto !important;
        }
        svg {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
  }

  .box--modal7 {
    .initial-title {
      margin-bottom: 10px;
    }
    .col--lottie  .lottie-player {
      width: 290px;
      height: 200px;
      div {
        // width: 340px !important;
        // height: 370px !important;
    }
    }
    .row {
      .box-modal-cols {
        &:first-child {
          height: 70%;
        }
        &:last-child {
          height: 30%;
        }
      }
    }
    .box-modal-article {
      .h3 {
        &:first-child {
          margin-bottom: .5rem;
        }
      }
      h2 {
        font-size: 32px;
        line-height: 32px;
        margin-bottom: .5rem;

      }
      .h2 {
        font-size: 20px;
        line-height: 35px;
      }
      .h3 {
        font-size: 16px;
        line-height: 25px;
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .box--modal7 {
    .col--lottie {
      .lottie-player {
        div {
          width: 60%;
          height: auto !important;
        }
        svg {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
  }
  .box--modal7 {
    .row {
      .box-modal-cols {
        &:first-child {
          height: 65%;
        }
        &:last-child {
          height: 35%;
        }
      }
    }
    .box-modal-article {
      p {
        margin-bottom: 0;
      }
    }
  }
}