/* ------------------------------------------------------------
     MIXINGS
------------------------------------------------------------ */
@mixin absolute-fullsize {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
}
@mixin absolute-fullsize-right-bottom {
     position: absolute;
     bottom: 0;
     right: 0;
     width: 100%;
     height: 100%;
}
   
@mixin absolute-fullsize-image {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     object-fit: cover;
     object-position: center;
}
@mixin relative-fullsize-image {
     width: 100%;
     height: 100%;
     object-fit: cover;
     object-position: center;
}
   
@mixin relative-contain-image {
     width: 100%;
     height: 100%;
     object-fit: contain;
     object-position: center;
}
   
@mixin absolute-center {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
}