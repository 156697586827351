/* ------------------------------------------------------------
     MODAL 6
------------------------------------------------------------ */
.box--modal6 {
  background: $c-Green800;
  .row {
    align-items: center;
  }
  .box-modal-cols {
    height: 100%;
    &:first-child {
      .doters-logo {
        display: none;
      }
    }
  }
  .col--lottie {
    display: flex;
    align-items: center;
    justify-content: center;
    .lottie-player {
      width: 420px;
      height: 490px;
      position: relative;
    }
  }
  .box-modal-article {
    height: 100%;
    max-height: 450px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;
  }
}




/* -----

MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
  .box--modal6 .col--lottie .lottie-player {
    //width: 310px;
    //height: 350px;
  }
  
}
@media screen and (max-width: 1200px) {
  
}
@media screen and (max-width: 992px) {
  .box--modal6 .col--lottie .lottie-player {
    width: 240px;
    height: 270px;
  }
}
@media screen and (max-width: 768px) {
  .box--modal6 {
    .row {
      .box-modal-cols {
        &:first-child {
          height: 60%;
          display: block;
          padding-top: 60px;
          .doters-logo {
            display: block;
            margin: 0 auto 32px auto
          }
        }        
        &:last-child {
          height: 40%;
          .doters-logo {
            display: none;
          }
        }
      }
    }
    .col--lottie {
      .lottie-player {
        width: 100%;
        height: 340px;
      }
    }
    
    .box-modal-article {
      text-align: center;
      padding: 0;
      max-height: initial;
      display: block;
      h2 {
        font-size: 56px;
        line-height: 60px;
        margin-bottom: 40px;
      }
    }
  
  }
  .box-modal5__image {
    width: 260px;
  }
}
@media screen and (max-width: 441px) {
  .box--modal6 {
    &[data-info="2"] {
      .box-modal-article h2 {
        margin-bottom: 0;
        & + p {
          margin-bottom: 32px;
        }
      }
    }
    .row {
      .box-modal-cols {
        &:first-child {
          height: 70%;
          padding-top: 45px;
          .doters-logo {
            display: block;
            margin: 0 auto 12px auto
          }
        }        
        &:last-child {
          height: 30%;
        }
      }
      .col--lottie .lottie-player {
        height: calc(40vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom)) !important;
        height: calc(40vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)) !important;
        display: flex;
      }
    }
   
    .box-modal-article {
      h2 {
        font-size: 32px;
        line-height: 32px;
        margin-bottom: 16px;
      }
      .number-dooter-info{
        font-size: 28px !important;
        margin-top: .5rem !important;
      }
      .h2 {
        font-size: 20px;
        line-height: 25px;
      }
      .h3 {
        font-size: 16px;
        line-height: 25px;
      }
      .init-title {
        margin-bottom: 0;
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .box--modal6 {
    .row {
      .box-modal-cols {
        &:first-child {
          height: 65%;
        }        
        &:last-child {
          height: 35%;
        }
      }

      .col--lottie .lottie-player {
        height: calc(35vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom)) !important;
        height: calc(35vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)) !important;
        display: flex;
      }
    }
  }
}