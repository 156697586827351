/* ------------------------------------------------------------
     BASE
------------------------------------------------------------ */
.app-main,
#root {
     height: 100%;
}
.doters-logo {
     width: 140px;
     img {
          width: 100%;
          margin: 0;
     }    
}
.base-logo {
     width: 130px;
     img {
          width: 100%;
     }
}

/* inner card */
.inner-card {
     background: #fff;
     border-radius: 16px;
     padding: 24px 40px;
     position: relative;
}


/* -----

MEDIAQUERIES 

----- */
@media screen and (max-width: 768px) {
     .doters-logo {
          width: 90px;  
     }
     .base-logo {
          width: 130px;
          margin: 0 auto;
        } 
     
}
@media screen and (max-width: 431px) {
     
}