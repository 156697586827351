/* ------------------------------------------------------------
     RESET
------------------------------------------------------------ */
html,
body {
    -webkit-text-size-adjust: none;
    /* For iphone Landscape */
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    -ms-font-smoothing: antialiased;
    /* For font looks antialiased */
    -moz-osx-font-smoothing: grayscale;
    /* Moz antialiased */
    text-rendering: optimizeLegibility;
    /* optimezy fonts */
    font-size: 16px;
    color: $c-Base;
    font-family: $c-FontRegular;
    line-height: 25px;
    height: 100%;   
    background: $c-Green800;
    touch-action: manipulation;
    -ms-touch-action: manipulation;
    -webkit-text-size-adjust: 100%; /* Evita el ajuste de texto en iOS */
    -ms-text-size-adjust: 100%;    /* Para navegadores antiguos de Microsoft */
}


/* headings */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
blockquote,
h5,
.h5,
h6,
.h6 {
    letter-spacing: -.5px;
    font-family: $c-FontBold;
}
h1,
.h1 {
    font-size: 48px;
    line-height: 55px;
}
h2,
.h2 {
    font-size: 40px;
    line-height: 55px;
}
h3,
.h3 {
    font-size: 32px;
    line-height: 40px;
}
h4,
.h4 {
    font-size: 24px;
    line-height: 35px;
}
h5,
.h5 {
    font-size: 18px;
    line-height: 25px;
}
h6,
.h6 {
    font-size: 14px;
    line-height: 25px;
}



/* outline */
* {
    -ms-touch-action: manipulation;
    -webkit-touch-action: manipulation;
    touch-action: manipulation;
    &:focus {
        outline: none !important;
    }
}

/* Placeholder */
::-webkit-input-placeholder {
   color: $c-Gray300 !important;
}
::-moz-placeholder {
    color: $c-Gray200 !important;
} /* firefox 19+ */
:-ms-input-placeholder {
    color: $c-Gray200 !important;
} /* ie */
:-moz-placeholder {
    color: $c-Gray200 !important;
}


/* Selection */
::selection {
    opacity: 1 !important;
}
::-moz-selection {
    opacity: 1 !important;
} 

/* mx auto */
.mx-auto {
    margin: 0 auto;
}
.col-reset {
    padding: 0;
}

/* Hidden */
.hidden {
    display: none !important;
}


/* Address */
address {
    font-style: initial;
}



/* Margin */
.mt8 {
    margin-top: 8px;
}
.mt16 {
    margin-top: 16px;
}
.mt24 {
    margin-top: 24px;
}
.mt32 {
    margin-top: 32px;
}
.mt40 {
    margin-top: 40px;
}
.mb8 {
    margin-bottom: 8px;
}
.mb16 {
    margin-bottom: 16px;
}
.mb24 {
    margin-bottom: 24px;
}
.mb32 {
    margin-bottom: 32px;
}
.mb40 {
    margin-bottom: 40px;
}

/* weight */
.font-400 {
    *,
    & {
        font-family: $c-FontMedium !important;
    }
}
.font-300 {
    *,
    & {
        font-family: $c-FontRegular !important;
    }
}
.font-700 {
    *,
    & {
    font-family: $c-FontBold;
    }
}
