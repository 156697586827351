/* ------------------------------------------------------------
     BOX
------------------------------------------------------------ */
.box-col {
     padding: 10px;
     z-index: 1;
     &.box--viewing {
          z-index: 9999999;
          background: transparent;
     }
     &.col--h {
          display: flex;
          > * {
               margin-right: 20px;
               &:last-child {
                    margin-right: 0;
               }
          }
     }
     &.box-small {
          .box__inner {
               -webkit-mask-image: url(../../../../public/assets/images/box-back/box-back-small.svg);
               mask-image: url(../../../../public/assets/images/box-back/box-back-small.svg);
               mask-repeat: no-repeat;
               mask-position: center top;
          }
     }
     &.box-large {
          .box__inner {
               -webkit-mask-image: url(../../../../public/assets/images/box-back/box-back-regular.svg);
               mask-image: url(../../../../public/assets/images/box-back/box-back-regular.svg);
               mask-repeat: no-repeat;
               mask-position: center top;
          }
     }
     &.box-large {
          &.box--60 {
               .box__inner {
                    -webkit-mask-image: url(../../../../public/assets/images/box-back/box-back-large.svg);
                    mask-image: url(../../../../public/assets/images/box-back/box-back-large.svg);
                    mask-repeat: no-repeat;
                    mask-position: center top;
               }
              
          }
     }
     &.box-large {
          &.box--100 {
               .box__inner {
                    -webkit-mask-image: url(../../../../public/assets/images/box-back/box-back-big.svg);
                    mask-image: url(../../../../public/assets/images/box-back/box-back-big.svg);
                    mask-repeat: no-repeat;
                    mask-position: center top;
               }
              
          }
     }
     &.box-small {
          &.box--40 {
               .box__inner {
                    -webkit-mask-image: url(../../../../public/assets/images/box-back/box-back-small-large.svg);
                    mask-image: url(../../../../public/assets/images/box-back/box-back-small-large.svg);
                    mask-repeat: no-repeat;
                    mask-position: center top;
               }
              
          }
     }
}
.box {
     width: 100%;
     background: $c-Green1000;
     border-radius: 10px;
     height: 100%;
     padding: 10px;
     position: relative;
}
.box__inner {
     position: absolute;
     bottom: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: $c-Green1;
     border-radius: 10px;
     transform-origin: bottom;
     transform: perspective(2000px) rotateX(0deg);
     transition: .4s all ease-in-out;
     box-shadow: 0 -30px 70px rgba(0, 0, 0, 0);
     mask-size: cover;
     cursor: pointer;     
     &:hover {
          transform: perspective(4000px) rotateX(-30deg);
          box-shadow: 6px 8px 8px 4px rgba(0, 0, 0, 0.7);
     }
}

/* -----

 MEDIAQUERIES 

----- */
@media screen and (max-width: 1200px) { 
   
}
@media screen and (max-width: 1080px) {
   
}
@media screen and (max-width: 992px) {
  
}
@media screen and (max-width: 431px) {
     .box__inner {
          box-shadow: 0px 2.667px 5.333px 0px rgba(0, 0, 0, 0.50);
     }
     .box-col {
          &.box-small,
          &.box-large,
          &.box-large.box--60,
          &.box-large.box--100,
          &.box-small.box--40 {
               .box__inner {
                    -webkit-mask-image: url(../../../../public/assets/images/box-back/box-back-mobile.svg);
                    mask-image: url(../../../../public/assets/images/box-back/box-back-mobile.svg);
                    mask-repeat: no-repeat;
                    mask-position: center top;
               }
          }
         
     }
}
