/* ------------------------------------------------------------
    MEDIUM
------------------------------------------------------------ */
@media screen and (max-width: 1024px) {
    h3,
    .h3 {
        font-size: 28px;
        line-height: 35px;
    }
   
}
@media screen and (max-width: 992px) {
    
   
}
@media screen and (max-width: 768px) {
    .text-24 {
        font-size: 18px;
        line-height: 25px;
    }
    h1,
    .h1 {
        font-size: 38px;
        line-height: 45px;
    }
    h2,
    .h2 {
        font-size: 24px;
        line-height: 30px;
    }
    h3,
    .h3 {
        font-size: 20px;
        line-height: 30px;
    }
    h4,
    .h4 {
        font-size: 21px;
        line-height: 27px;
    }
    
    p {
        font-size: 14px;
        line-height: 21px;
    }
}