/* ------------------------------------------------------------
    INTRO
------------------------------------------------------------ */
.main-view {
  height: 100%;
  &.end--view {
    .intro-plain {
      bottom: -5%;
    }
    .base-logo {
      margin-bottom: 32px;
    }
    h1 {
      line-height: 90px;
    }
    .main-view__right  {
      padding: 0 5%;
    }
   
  }
  .container-fluid {
    height: 100%;
  }
  .row {
    height: 100%;
    > * {
      height: 100%;
    }
  }
}
.main-view__aside {
  background: url(../../../../public/assets/images/background/passport-back.svg) center center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  &.aside--color2 {
    background: $c-Green500;
  }
  figure {
    margin: 0;
    width: 350px;
    right: -12%;
    position: relative;
    img {
      width: 100%;
    }
  }
  .lottie-player  {
    width: 70%;
    height: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.main-view__right {
  display: flex;
  align-items: center;
  padding: 0 15%;
  position: relative;
  overflow: hidden;
}

.intro-section__subtitle {
  display: inline-flex;
  align-items: center;
  strong {
       font-size: 96px;
       line-height: 100px;
       margin-left: 16px;
  }
}
.main-article-intro {
  h1 {
    margin-bottom: 40px;
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  article {
    margin-bottom: 60px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.intro-plain {
  position: absolute;
  right: 0;
  bottom: 15%;
  width: 33%;
  img {
    width: 100%;
  }
}
.passport-complete {
  display: none;
}


.main-view.end--view {
  min-height: 750px;
}

/* -----

MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
  .main-view {
    &.end--view {
      .intro-plain {
        bottom: -10% ;
      }
      .main-article-intro article p {
        padding-right: 20%;
      }
      .intro-plain {
        width: 320px;
      }
    }
  }
  .main-view__right {
    padding: 0 5%;
  }
  .main-view.end--view .intro-plain {
    bottom: 0;
    margin: 0;
  }
}
@media screen and (max-width: 1200px) {
  .main-view {
    &.end--view {
      .intro-plain {
        display: none;
      }
    }
  }
  .main-view {
    &.end--view {
      .main-view__right {
        article {
          &:last-child {
            padding-right: 0%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .main-view {
   &.end--view h1 {
      line-height: 60px;
    }
    .row > * {
      height: auto;
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
  .main-view__aside {
    background: $c-Green300;
    justify-content: center;
    figure {
      width: 430px;
      right: initial;
    }
  }
  .passport-complete {
    display: block;
  }
  .passport {
    display: none;
  }
  .intro-plain {
    display: none;
  }
  .main-view__right {
    article {
      &:last-child {
        padding-right: 0%;
      }
    }
  }
  .main-view {
    &.end--view {
      .main-view__right {
        .intro-plain {
          width: 35%;
          bottom: -10%;
        }
      }
    }
  }
 
  .main-view__aside .lottie-player {
    width: 300px;
    height: 300px; 
  }
  
}
@media screen and (max-width: 768px) {
  .main-view {
    &.end--view {
      .intro-plain {
        bottom: 5%;
        display: flex;
        width: 45%;
        right: -7%;
        bottom: 0%;
      }
      .main-view__aside .lottie-player {
        width: 60%;
        height: auto;
      }
    }
  }
  .main-view .row > * {
    &:first-child {
      padding-top: 60px;
      padding-bottom: 60px;
      height: 45%;
      min-height: 460px;
    }
  }
  .intro-section__subtitle {
    margin-top: 32px;
    strong {
      font-size: 56px;
      line-height: 60px;
    }
  }
  .main-view__right article {
    text-align: center;
    &:last-child { 
      padding-right: 0;
    }
  }
  .main-article-intro {
    display: flex;
    flex-wrap: wrap;
    .base-logo {
      order: 3;
      margin-top: 140px;
    }
  }
  .main-view.end--view {
    .main-view__right {
      align-items: flex-start;
    }
    h1 {
      margin-bottom: 16px;
      line-height: 40px;
    }
  }
  .main-view.end--view .main-article-intro article {
    p {
      padding-right: 0;
    }
  }
}
@media screen and (max-width: 441px) {
  .main-view {
  
  &.end--view {
    .intro-plain {
      bottom: 5%;
      display: flex;
      width: 45%;
      right: -7%;
    }
    .main-view__aside .lottie-player {
      width: 60%;
      height: auto;
    }
    .main-view__right {
      height: 50% !important;
    }
  }
  }
  .main-article-intro {
    display: flex;
    flex-wrap: wrap;
    .base-logo {
      order: 3;
      margin-top: 32px;
    }
  }
  .main-view .row > * {
    &:first-child {
      height:35%;
      min-height: initial;
    }
  }
  .intro-section__subtitle {
    strong {
      font-size: 42px;
      line-height: 50px;
    }
  }
  .intro-plain {
    display: none !important;
  }
  .main-view__right {
    article {
      margin-bottom: 24px;
      h1 {
        margin-bottom: 16px;
        margin-top: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
  
  
}

@media screen and (max-width: 376px) {
  .main-view .row > * {
    &:first-child {
      height: 80%;
      .lottie-player{
        width: 60%;
        height: auto;
      }
    }
  }
  .main-view {
  &.end--view {
    .intro-plain {
      bottom: 0% !important;
      width: 45%;
      right: -7%;
    }
    &:first-child {
      height: 60%;
    }
    .row > *:first-child {
      height: 30%;
    }
    .main-view__right {
      height: 70% !important;
    }
  }
  .end-article{
    h1{
      font-size: 24px;
      line-height: normal;
      margin-bottom: px;
    }
    strong{
      font-size: 24px;
      line-height: normal;

    }
    p{
      font-size: 16px;
      line-height: normal;
    }
    
  }

  .main-view {
  
    &.end--view {
      .main-view__aside .lottie-player {
        width: 20%;
        height: auto;
      }
    }
    }
    .main-view .row > * {
      &:first-child {
        height: 20%;
      }
    }
  .main-article-intro{
    padding-top: 1rem;
  }
  }
  .intro-section__subtitle {
    strong {
      font-size: 42px;
      line-height: 50px;
    }
  }
  .intro-plain {
    display: none;
  }
  .main-view__right article {
    margin-bottom: 24px;
    h1 {
      margin-bottom: 16px;
      margin-top: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  .main-article-intro {
    display: flex;
    flex-wrap: wrap;
    .base-logo {
      order: 3;
      margin-top: 0px;
    }
  }
  
  
}