/* ------------------------------------------------------------
     MODAL CF
------------------------------------------------------------ */
.box--modalCF {
  background: url(../../../../public/assets/images/background/back-tree.jpg) center center no-repeat;
  background-size: cover;
  .row {
    align-items: center;
    justify-content: center;
  }
}
.card-double-section {
  height: 430px;
  .row {
    > * {
      border-radius: 18px;
      position: relative;
      height: 100%;
    }
  }
 
  article {
    background: #fff;
    text-align: left;
    padding: 32px;
    display: flex;
    align-items: center;
    position: relative;
    &:after {
      content: '';
      width: 1px;
      border-right: 1px dashed $c-Base;
      height: 90%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -1px;
      z-index: 1;
    }
    p {
      margin-bottom: 32px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  aside {
    .lottie-player {
      width: 300px;
      height: 400px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}



/* -----

MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
  .card-double-section {
    p {
      font-size: 26px;
      line-height: 40px;
    }
    .text-120 {
      font-size: 72px;
      line-height: 70px;
    }
    aside .lottie-player {
      width: 280px;
    height: 310px;
    }
  }
}
@media screen and (max-width: 1200px) {
  
}
@media screen and (max-width: 992px) {
  .card-double-section {
    &[data-number="2"] {
      article {
        p {
          margin-bottom: 8px;
          &:last-child {
            font-size: 24px;
            line-height: 30px;
          }
        }
      }
    }
  }
  
}
@media screen and (max-width: 768px) {
  .box--modalCF {
    .box-modal-cols {
      padding: 30px;
      
    }
  }
  .card-double-section {
    height: auto;
    .row > * {
      &:first-child {
        height: auto;
        padding: 40px 32px;
        order: 2;
        text-align: center;
        justify-content: center;
      }
      &:last-child {
        order: 1;
        height: 230px;
      }
    }
    .text-120 {
      font-size: 48px;
      line-height: 50px;
    }
    article {
      p {
        font-size: 28px;
        line-height: 35px;
        margin-bottom: 16px;
      }
    }
  }
  .card-double-section {
    aside {
      .lottie-player {
        width: 190px;
        height: 210px;
      }
    }
    &[data-number="2"] {
      .row {
        > * {
          &:first-child {
            padding: 40px 32px;
          }
        } 
      }
      article {
        p {
          &:last-child {
            margin-top: 32px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 441px) {
  .card-double-section {
   
    .row > * {
      &:first-child {
        padding: 20px 16px;
      }
     
    }
    article {
      &:after {
        width: 80%;
        height: 1px;
        border-bottom: 1px dashed $c-Base;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        opacity: .5;
      }
      p {
        font-size: 20px;
        line-height: 30px;
      }
    }
    .text-120 {
      font-size: 28px;
      line-height: 35px;
    }
  }
}
