/* ------------------------------------------------------------
     MODAL 8
------------------------------------------------------------ */
.box--modal8 {
  background: url(../../../../public/assets/images/background/back-pattern1.svg) center center no-repeat;
  background-size: cover;
  .row {
    align-items: center;
  }
  article {
    text-align: left;
    height: 470px;
    display: flex;
    align-items: center;
    width: 60%;
  }
  .mid-title {
    margin-bottom: 32px;
  }
  .lottie-player {
    left: initial;
    display: flex;
    width: 350px;
    height: 470px;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
  }
}


/* -----

MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
  .box--modal8 {
    
   
    .lottie-player {
      width: 30%;
      height: auto;
    }
  }
}
@media screen and (max-width: 1024px) { 
  .box--modal8 {
    .inner-card {
      .h2 {
        font-size: 28px;
        line-height: 35px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .box--modal8 {
    background: url(../../../../public/assets/images/background/back-pattern1-mobile.svg) center center no-repeat;
    background-size: cover;
    article {
      width: 100%;
      height: auto;
      padding-top: 400px;
      text-align: center;
      justify-content: center;
    }
    .lottie-player {
      width: 260px;
      height: 300px;
      left: 50%;
      transform: translateX(-50%);
      top: 9%;
    }
  }
  .inner-card {
    max-width: 356px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 441px) {
  .box--modal8 {
    .lottie-player {
      top: -3%;
      padding: 2rem;
    }
    article {
      padding-top: 250px;
    }
    
    .inner-card {
      padding-left: 25px;
      padding-right: 25px;
      .h2 {
        font-size: 21px;
        line-height: 30px;
        margin-bottom: 0;

      }
      .paragraph-minor{
        font-size: 18px;
        line-height: 30px;
      }
    }
    .box-modal-cols {
      padding: 20px;
      .inner-card {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .box--modal8 {
    .lottie-player {
      width: 70%;
      height: auto;
    }
    article {
      padding-top: 200px;
    }
  }
  .inner-card {
    padding-left: 8px;
    padding-right: 8px;
  }
}
