/* ------------------------------------------------------------
     MODAL 11
------------------------------------------------------------ */
.box--modal11 {
  background: url(../../../../public/assets/images/background/back-pattern3.svg) center center no-repeat;
  background-size: cover;
  .row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .inner-card {
    position: relative;
    padding-left: 35%;
    text-align: left;
    min-height: 470px;
    display: flex;
    align-items: center;
  }
  .lottie-player {
    width: 290px;
    height: 240px;
    display: flex;
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
  }
  .mid-title {
    margin-bottom: 32px;
  }
}


/* -----

MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
  .box--modal11 {
    .inner-card {
      padding-left: 45%;
    }
    .lottie-player {
      width: 260px;
      height: 230px;
    }
  }
}
@media screen and (max-width: 1200px) {
  
}
@media screen and (max-width: 992px) {
  
}
@media screen and (max-width: 768px) {
  .box--modal11 {
    background: url(../../../../public/assets/images/background/back-pattern3-mobile.svg) center center no-repeat;
    background-size: cover;
    .inner-card {
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 340px;
      text-align: center;
    }
    .lottie-player {
      top: 50px;
      left: 50%;
      transform: translateX(-50%);
    }
    .box-modal-cols {
      padding: 20px;
      .inner-card {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}
@media screen and (max-width: 441px) {
  .box--modal11 {
    .lottie-player {
      padding: 1.5rem;
      top: 2%;
    }
    .inner-card {
      padding-top: 250px;
      .h2 {
        font-size: 21px;
        line-height: 30px;
        margin-bottom: 0;
      }
      .paragraph-minor{
        font-size: 18px;
        line-height: 30px;
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .box--modal11 {
    .box-modal-cols {
      padding: 24px;
    }
    .lottie-player {
      width: 90%;
      height: auto;
    }   
    .inner-card {
      padding-top: 280px;
      p {
        font-size: 18px;
        line-height: 25px;
      }
     
      h2 {
        font-size: 21px;
        line-height: 30px;
      }
    }
  }
}
