/* ------------------------------------------------------------
     MODAL 11
------------------------------------------------------------ */
.box--modal12 {
  max-height: 650px;
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  background: $c-Green800;

  .row {
    align-items: center;
  }
  .box-modal-cols {
    position: relative;
    height: 100%;
  }
  article {
    text-align: left;
  }
  .col--info {
    display: flex;
    align-items: center;
    padding: 0 32px;
    background: $c-Green800;
  }
  .info-title {
    margin-bottom: 40px;
  }
  .info-row {
    margin-bottom: 40px;
    > div {
      padding-left: 0;
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .col-lottie-end {
    p {
      position: absolute;
      z-index: 1;
      left: 47%;
      transform: rotate(355deg);
      transform-origin: left;
      top: 19%;
      // font-size: 20px;
      font-family: $c-FontBold;
      color: $c-Green800;
    }
  }
}

/* -----

MEDIAQUERIES

----- */
// @media screen and (max-width: 1400px) {
//   .box--modal12 .col-lottie-end p {
//     left: 50%;
//   }
// }
@media screen and (max-width: 1200px) {
  .box--modal12 {
    .info-title {
      margin-bottom: 24px;
      font-size: 24px;
      line-height: 30px;
    }
    .btn {
      font-size: 12px;
      width: 100%;
      text-wrap: nowrap;
    }
    .col-lottie-end {
      p {
        left: 46%;
        font-size: 18px;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .box--modal12 
    .col-lottie-end {
      .lottie-player {
        top: 0px;
        height: 100%;
        width: 100%;
        overflow: hidden !important;
      }
      p {
        left: 47.5%;
        font-size: 16px;
        top: 20%;
      }
    }

}
@media screen and (max-width: 768px) {
  .box--modal12 {
    min-height: -webkit-fill-available;
    height: calc(84vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom)) !important;
    height: calc(84vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)) !important;

    .btn {
      padding: 0 10px;
    }
    .box-modal-cols {
      &:first-child {
        height: 50%;
      }
      &:last-child {
        height: 50%;
        padding: 24px 15px;
      }
    }
    .info-title {
      text-align: center;
      font-size: 18px;
      line-height: 25px;
    }
    .info-row {
      > div {
        width: 50%;
        &:last-child {
          width: 100%;
        }
      }
    }
    .col-lottie-end {
      overflow: hidden;
      .lottie-player {
        top: 10px;
        height: 120%;
        padding: 0rem;
      }
      p {
        left: 47.5%;
        top: 55px;
        font-size: clamp(10px, 4vw, 14px);
      }
    }
  }
}
@media screen and (max-width: 441px) {
  .box--modal12 {
    height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));

    .box-modal-cols {
      &:first-child {
        height: calc(40% - env(safe-area-inset-top));
      }
      &:last-child {
        height: calc(60% - env(safe-area-inset-bottom));
      }

      article {
        text-align: center;
      }
    }

    .col-lottie-end p {
      left: 47%;
      top: 18%;
      width: 200px;
      transform-origin: left;
    }
    .info-title {
      margin-bottom: 10px;
    }
    .info-row {
      margin-bottom: 10px;
      > div {
        padding-left: 0;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}


@media screen and (max-width: 375px) {
  .box--modal12 {
    .box-modal-cols {
      &:first-child {
        height: 40%;
      }
      &:last-child {
        height: 60%;
      }
    }
    article {
      p {
        margin-bottom: 0;
      }
    }
    .info-row {
      margin-bottom: 16px;
    }
    .col-lottie-end {
      overflow: hidden;
      .lottie-player {
        height: 130%;
      }
      p {
        top: 17%;
        left: 47%;
      }
    }
  }
}