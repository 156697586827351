/* ------------------------------------------------------------
     MODAL 3
------------------------------------------------------------ */
.box--modal3 {
  background: $c-Green700;
  .row {
    align-items: center;
  }
  .box-modal-article {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 32px;
  }

  .lottie-player {
    overflow: hidden;
  }
}

/* -----

MEDIAQUERIES

----- */
@media screen and (max-width: 1400px) {
}
@media screen and (max-width: 1024px) {
  .box--modal3 {
    h2 {
      margin-bottom: 24px;
    }
    .box-modal-article {
      .h3 {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}
@media screen and (max-width: 992px) {
}
@media screen and (max-width: 768px) {
  .box--modal3 {
    .row {
      .box-modal-cols {
        &:first-child {
          height: 60%;
        }
        &:last-child {
          height: 40%;
        }
      }
    }
    .box-modal-article {
      text-align: center;
      padding: 0;
      gap: 0;
      h2 {
        font-size: 24px;
        line-height: 1.6;
        margin-bottom: 0px;
      }
      p{
        margin-bottom: 0px;
        line-height: 1.3;
      }
      .h2 {
        font-size: 20px;
        line-height: 35px;
      }
      .h3 {
        font-size: 16px;
        line-height: 1.3;
      }
    }
  }
  .box-modal-article__priority {
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 441px) {
  .box--modal3 {
    .row {
      .box-modal-cols {
        &:first-child {
          height: 65%;
        }
        &:last-child {
          height: 35%;
        }
      }
    }
    .number-times-text{
      margin-bottom: 0 !important;
      font-size: 32px !important;
    }
    .box-modal-article__priority {
      margin-bottom: 0px;
    }
    .box-modal-article {
      h2 {
        font-size: 24px;
        line-height: 1.6;
        margin-bottom: 0px;
      }
      p{
        margin-bottom: 0px;
        line-height: 1.3;
      }
      .h2 {
        font-size: 20px;
        line-height: 35px;
      }
      .h3 {
        font-size: 16px;
        line-height: 1.3;
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .box--modal3 {
    .row {
      .box-modal-cols {
        &:first-child {
          height: 65%;
        }
        &:last-child {
          height: 35%;
        }
      }
    }
    .box-modal-article__priority {
      margin-bottom: 0px;
    }
  }
}
