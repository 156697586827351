/* ------------------------------------------------------------
     LOADER
------------------------------------------------------------ */
.loader-element {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     z-index: 99999;
     background: transparent;
     display: flex;
     align-items: center;
     justify-content: center;
}


/* loader small */
.loader-small {
     position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader {
     width: 48px;
     height: 48px;
     border-radius: 50%;
     display: inline-block;
     position: relative;
     border: 3px solid;
     border-color: #FFF #FFF transparent;
     box-sizing: border-box;
     animation: rotation 1s linear infinite;
   }
   .loader::after {
     content: '';  
     box-sizing: border-box;
     position: absolute;
     left: 0;
     right: 0;
     top: 0;
     bottom: 0;
     margin: auto;
     border: 3px solid;
     border-color: transparent $c-Green300 $c-Green300;
     width: 24px;
     height: 24px;
     border-radius: 50%;
     animation: rotationBack 0.5s linear infinite;
     transform-origin: center center;
   }
   
   @keyframes rotation {
     0% {
       transform: rotate(0deg);
     }
     100% {
       transform: rotate(360deg);
     }
   } 
       
   @keyframes rotationBack {
     0% {
       transform: rotate(0deg);
     }
     100% {
       transform: rotate(-360deg);
     }
   }
       

/* -----

 MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
   
}
@media screen and (max-width: 1200px) {
    
}
@media screen and (max-width: 992px) {
  
}
@media screen and (max-width: 820px) {
   
}