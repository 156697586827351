/* ------------------------------------------------------------
     MODAL 4
------------------------------------------------------------ */
.box--modal4 {
  .row {
    align-items: center;
  }
  .box-modal-cols {
    padding: 0;
    position: relative;
    z-index: 1;
  }
  .lottie-player {
    padding: 0;
    border-radius: 8px;
    overflow: hidden;

    svg {
      position: absolute;
      top: 0;
      right: 0;
      width: 140%;
    }
  }
  .box-modal-article {
    padding: 0 32px;
    position: relative;
    top: -16px;
  }
  .box-modal-article__priority {
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 1400px) {
  .box--modal4 {
    .lottie-player {
      svg {
        left: 0;
        right: initial;
      }
    }
  }
}


/* -----

MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
  .box--modal4 {
    .box-modal-article {
      padding-left: 86px;
    }
    .lottie-player {
      svg {
      
        width: 115% !important;
      }

      &.lottie-player--2 {
        svg {
          left: 0;
          right: initial;
        }
      }
    }
  }
 
}
@media screen and (max-width: 1024px) {
  .box--modal4 {
    .box-modal-article {
      padding: 12px 32px;
      background: #ffffff9e;
      backdrop-filter: blur(10px);
      border-radius: 10px;
      .h3 {
        font-size: 20px;
        line-height: 30px;
      }
    }
    .lottie-player {
      svg {
      
        width: 140% !important;
      }
    }
  }
}

@media screen and (min-width: 993px) and (max-width: 1024px) {
  .box--modal4 {
    .lottie-player {
      svg {
       
        width: 120% !important;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .box--modal4 {
    .lottie-player {
      svg {
        width: 120% !important;
      }
    }
  }
}
@media screen and (max-width: 910px) {
  .box--modal4 {
    .lottie-player {
      svg {
        position: absolute;
        top: 0;
        right: 0;
        width: 130% !important;
      }

      &.lottie-player--2 {
        svg {
          left: 0;
          right: initial;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .box--modal4 {
    background: #fff;
    .row {
      align-items: flex-end;
      .box-modal-cols {
        height: 40%;
      }
    }
    .lottie-player {
      border-radius: 8px 8px 0 0;
      height: 60%;
    }
    .box-modal-article {
      text-align: center;
      padding: 0;
      top: 0;
      h2 {
        font-size: 56px;
        line-height: 60px;
      }
    }
  }
}
@media screen and (max-width: 441px) {
  .box--modal4 {
    .row {
      .box-modal-cols {
        height: 30%;
        padding: 0 15px;
      }
    }
    .box-modal-article__priority {
      margin-bottom: 8px;
    }
    .lottie-player {
      height: 70%;
    }
    .box-modal-article {
      h2 {
        font-size: 32px;
        line-height: 32px;
      }
      .h2 {
        font-size: 20px;
        line-height: 35px;
      }
      .h3 {
        font-size: 16px;
        line-height: 25px;
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .box--modal4 {
    .row {
      .box-modal-cols {
        height: 35%;
      }
    }
    .lottie-player {
      height: 65%;
    }
   
  }
}
