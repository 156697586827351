/* ------------------------------------------------------------
    ORIENTATION MESSAGE
------------------------------------------------------------- */
.orientation-message {
     text-align: center;
     display: flex;
     justify-content: center;
     align-items: center;
     height: 100vh;
     max-width: 450px;
     width: 90%;
     margin: 0 auto;
     * {
          &:last-child {
               margin-bottom: 0;
          }
     }
     figure {
          width: 100px;
          margin: 0 auto 24px auto;
          img {
               width: 100%;
          }
     }
}

/* -----

 MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
   
}
@media screen and (max-width: 1200px) {
   
}
@media screen and (max-width: 431px) {
     
}