/* ------------------------------------------------------------
     VARIABLES
------------------------------------------------------------ */
// Base 
$c-Base: #002612;
// Secondary
$c-Green1: #D9D9D9;
$c-Green10: #b8e8d5;
$c-Green20: #EAF1EC;
$c-Green50: #25E99B;
$c-Green100: #93D500;
$c-Green150: #8CD400;
$c-Green200: #51C662;
$c-Green300: #9BE904;
$c-Green350: #00DF00;
$c-Green400: #38AC49;
$c-Green500: #00AE42;
$c-Green600: #00853F;
$c-Green700: #1A5632;
$c-Green800: #10371F;
$c-Green900: #002612;
$c-Green1000: #00190c;
$c-Red100: #FF856D;
$c-Red300: #EB0028;
$c-Red400: #FF4D29;
$c-Red500: #DB0011;
$c-Red600: #AF351C;
$c-Yellow100: #FAD556;
$c-Yellow400: #F7C02E;
$c-Orange300: #FFAE5A;
$c-Orange400: #FF8300;
$c-Blue200: #93B8F5;
$c-Blue400: #2771EB;
$c-Blue600: #0D3E8F;
$c-Doters: #004951;
// Gray
$c-Gray100: #F6F6F6;
$c-Gray200: #D2D2D2;
$c-Gray300: #819288;

// Shade
$c-Shade: rgb(33 33 33 / 89%);
$c-Shade2: rgb(0 74 35 / 49%);
// Gradient

// Import
@font-face {
     font-family: 'Poppins-Bold';
     src: url('../../../../public/fonts/Poppins-Bold.ttf') format('truetype');
     font-weight: 300;
     font-display: swap
}
@font-face {
     font-family: 'Poppins-SemiBold';
     src: url('../../../../public/fonts/Poppins-SemiBold.ttf') format('truetype');
     font-weight: 300;
     font-display: swap
}
@font-face {
     font-family: 'Poppins-Medium';
     src: url('../../../../public/fonts/Poppins-Medium.ttf') format('truetype');
     font-weight: 300;
     font-display: swap
}
@font-face {
     font-family: 'Poppins-Regular';
     src: url('../../../../public/fonts/Poppins-Regular.ttf') format('truetype');
     font-weight: 300;
     font-display: swap
}
@font-face {
     font-family: 'Poppins-Light';
     src: url('../../../../public/fonts/Poppins-Light.ttf') format('truetype');
     font-weight: 300;
     font-display: swap
}

// Font
$c-FontRegular:  'Poppins-Regular', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; 
$c-FontMedium:  'Poppins-Medium', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; 
$c-FontLight:  'Poppins-Light', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; 
$c-FontSemiBold:  'Poppins-SemiBold', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; 
$c-FontBold:  'Poppins-Bold', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; 