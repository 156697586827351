/* ------------------------------------------------------------
     MASONRY
------------------------------------------------------------ */
.masonry {
     width: 100%;
}


/* -----

 MEDIAQUERIES 

----- */
@media screen and (max-width: 1200px) { 
   
}
@media screen and (max-width: 1080px) {
   
}
@media screen and (max-width: 992px) {
  
}
@media screen and (max-width: 768px) {
     .masonry {
          margin-bottom: 60px;
     }
}
