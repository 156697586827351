/* ------------------------------------------------------------
     MODAL PB
------------------------------------------------------------ */
.box--modalPB {
  background: url(../../../../public/assets/images/background/back-pattern4.svg) center center no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
  .row {
    align-items: center;
  }
  article {
    position: relative;
    z-index: 1;
    text-align: center;
    max-width: 670px;
    left: -4%;
  }
  .box-modal-cols {
    display: flex;
    justify-content: center;
  }
}
.modalPB__title {
  margin-bottom: 32px;
}


/* -----

MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
 
}
@media screen and (max-width: 1200px) {
  
}
@media screen and (max-width: 992px) {
  .box--modalPB {
    &[data-number="2"] {
      article {
        padding: 0 90px;
      }
    }
    .lottie-player {
      top: 50%;
      left: 50%;
      width: 110%;
      height: 70%;
      transform: translate(-50%, -50%);
  
    }
  }
  
}
@media screen and (max-width: 768px) {
  .box--modalPB {
    background: url(../../../../public/assets/images/background/back-pattern4-mobile.svg) center center no-repeat;
    background-size: cover;
    &[data-number="2"] {
      article {
        padding: 60px 20% 0 20%; 
      }
    }

   .lottie-player {
      top: 45%;
      left: 50%;
      width: 100%;
      height: 90%;
   }
   article {
    left: 0;
    h2 {
      margin-bottom: 32px;
    }
   }
  }
  
}


@media screen and (max-width: 441px) {
  .box--modalPB {
    &[data-number="2"] {
      article {
        padding: 0 60px;
        padding-top: 70px;
      }
    }
    .lottie-player {
      top: 46%;
        left: 50%;
        width: 100%;
        height: 95%;
    }
    article {
      padding: 0 40px;
      p {
        margin-bottom: 12px;
      }
    }
  }
}


@media screen and (max-width: 431px) {
  .box--modalPB {
    &[data-number="2"] {
      article {
        padding: 0 60px;
        padding-top: 70px;
      }
    }
    .lottie-player {
      top: 46%;
        left: 50%;
        width: 100%;
        height: 95%;
    }
    article {
      padding: 0 40px;
      p {
        margin-bottom: 12px;
        font-size: 24px;

      }
      h2 {
        font-size: 26px;
      }
    }
  }
}


@media screen and (max-width: 375px) {
  .box--modalPB {
    &[data-number="2"] {
      article {
        padding: 0 60px;
        padding-top: 70px;
      }
    }
    .lottie-player {
      top: 46%;
        left: 50%;
        width: 100%;
        height: 95%;
    }
    article {
      padding: 0 40px;
      p {
        margin-bottom: 12px;
        font-size: 24px;

      }
      h2 {
        font-size: 26px;
      }
    }
  }
}
