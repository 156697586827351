/* ------------------------------------------------------------
     TEXT COLOR
------------------------------------------------------------- */
.text-green700 {
     color: $c-Green700;
}
.text-green500 {
     color: $c-Green500;
}

.text-green300 {
     color: $c-Green300;
}


/* -----

 MEDIAQUERIES 

----- */
@media screen and (max-width: 1400px) { 
   
}
@media screen and (max-width: 1200px) {
    
}
@media screen and (max-width: 992px) {
    
}
@media screen and (max-width: 680px) {
   
}